//
//
// https://tieto.nurminen.dev sivuston lähdekoodi
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// ALL RIGHTS RESERVED
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


//
// webpack entry file
//

__webpack_nonce__ = 'jksdfksdfjkladsfklsjdfklasdkljf23893fj';
import '../css/styles.scss'

import { jwtDecode }                from 'jwt-decode'
import smoothscroll                 from 'smoothscroll-polyfill'
import * as zenscroll               from 'zenscroll'
import MicroModal                   from 'micromodal'
import { Notyf }                    from 'notyf'

import 'notyf/notyf.min.css';

import 'FRONTEND/images/favicon.ico'

export const notyf = new Notyf({
    duration: 5000,
    dismissible: true
})

smoothscroll.polyfill()

const zenscrollDefaultDuration = 300 // ms
const zenscrollEdgeOffset = 25 // px
zenscroll.setup(zenscrollDefaultDuration, zenscrollEdgeOffset)



// 
// 
// ██╗      ██████╗  ██████╗ ██╗███╗   ██╗
// ██║     ██╔═══██╗██╔════╝ ██║████╗  ██║
// ██║     ██║   ██║██║  ███╗██║██╔██╗ ██║
// ██║     ██║   ██║██║   ██║██║██║╚██╗██║
// ███████╗╚██████╔╝╚██████╔╝██║██║ ╚████║
// ╚══════╝ ╚═════╝  ╚═════╝ ╚═╝╚═╝  ╚═══╝
//
//
async function login(from, e) {
    e.preventDefault()

    try {
        const emailElId = from === 'top-login' ? 'tieto-kirjaudu-email' : 'kurssi-kirjaudu-email'
        const pwdElId = from === 'top-login' ? 'tieto-kirjaudu-salasana' : 'kurssi-kirjaudu-salasana'
        const staySigneElId = from === 'top-login' ? 'tieto-pysy-kirjautuneena' : 'kurssi-pysy-kirjautuneena'

        const emailInputEl = document.getElementById(emailElId)
        const pwdInputEl = document.getElementById(pwdElId)

        const email = emailInputEl?.value
        const password = pwdInputEl?.value

        if(!email || !password) {
            return notyf.error('Syötä sähköposti ja salasana')
        }

        const staySignedInCheckboxEl = document.getElementById(staySigneElId)
        const staySignedIn = staySignedInCheckboxEl?.checked || false

        const payload = { email, password, staySignedIn }

        showLoadingIndicator()

        const userData = await backendApiKutsu('POST', '/api/user/login', payload)

        await setLoggedIn(userData)

    } catch(error) {
        if(error?.status === 401) {
            notyf.error('Väärä sähköpostiosoite tai salasana')
        } else {
            console.error(error)
            notyf.error('Palvelinvirhe. Jos ongelma jatkuu, ota yhteys kurssit@nurminen.dev')
        }
    } finally {
        hideLoadingIndicator()
    }

}


async function logout() {
    try {
        showLoadingIndicator('Kirjaudutaan ulos...')

        sessionStorage.removeItem('tieto-authorization')

        await backendApiKutsu('POST', '/api/user/logout')

        window.location.reload()

    } catch(error) {
        console.error(error)

        notyf.error('Uloskirjautuminen ei onnistu, jos ongelma jatkuu, ota yhteys kurssit@nurminen.dev')
    } finally {
        hideLoadingIndicator()
    }

}



// 
// 
//  █████╗ ██╗   ██╗████████╗██╗  ██╗
// ██╔══██╗██║   ██║╚══██╔══╝██║  ██║
// ███████║██║   ██║   ██║   ███████║
// ██╔══██║██║   ██║   ██║   ██╔══██║
// ██║  ██║╚██████╔╝   ██║   ██║  ██║
// ╚═╝  ╚═╝ ╚═════╝    ╚═╝   ╚═╝  ╚═╝
//
//
async function authenticate() {
    try {
        showLoadingIndicator()

        const userData = await backendApiKutsu('POST', '/api/user/authenticate')

        await setLoggedIn(userData)

    } catch(error) {
        // No active login session / cookie
    } finally {
        hideLoadingIndicator()
    }

}



//
//
// ███████╗███████╗████████╗██╗      ██████╗  ██████╗ ██╗███╗   ██╗
// ██╔════╝██╔════╝╚══██╔══╝██║     ██╔═══██╗██╔════╝ ██║████╗  ██║
// ███████╗█████╗     ██║   ██║     ██║   ██║██║  ███╗██║██╔██╗ ██║
// ╚════██║██╔══╝     ██║   ██║     ██║   ██║██║   ██║██║██║╚██╗██║
// ███████║███████╗   ██║   ███████╗╚██████╔╝╚██████╔╝██║██║ ╚████║
// ╚══════╝╚══════╝   ╚═╝   ╚══════╝ ╚═════╝  ╚═════╝ ╚═╝╚═╝  ╚═══╝
//
//
async function setLoggedIn(userData) {
    // Close and hide login menu
    const loginMenuEl = document.getElementById('login-menu')
    loginMenuEl.classList.remove('is-active')
    loginMenuEl.style.display = 'none'

    // Show and setup user menu
    const userMenuEl = document.getElementById('user-menu')
    
    if(!userMenuEl) return

    userMenuEl.style.display = 'inline-flex'

    const accountEmailEl = document.getElementById('user-menu-account-email')
    if(accountEmailEl) {
        accountEmailEl.innerHTML = userData.email
    }

    await setFrontPage(userData)
    await setCoursePage(userData)
    await setAccountPage(userData)
}



//
//
// ███████╗██████╗  ██████╗ ███╗   ██╗████████╗██████╗  █████╗  ██████╗ ███████╗
// ██╔════╝██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██╔══██╗██╔════╝ ██╔════╝
// █████╗  ██████╔╝██║   ██║██╔██╗ ██║   ██║   ██████╔╝███████║██║  ███╗█████╗
// ██╔══╝  ██╔══██╗██║   ██║██║╚██╗██║   ██║   ██╔═══╝ ██╔══██║██║   ██║██╔══╝
// ██║     ██║  ██║╚██████╔╝██║ ╚████║   ██║   ██║     ██║  ██║╚██████╔╝███████╗
// ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚══════╝
//
//
async function setFrontPage(userData) {
    const frontPageEl = document.getElementById('tieto-etusivu')

    if(!frontPageEl) return

    if(!Array.isArray(userData?.products) || userData.products.length === 0) return

    const courseEntries = document.getElementsByClassName('kurssi-entry')

    for(let i = 0; i < courseEntries.length; i++) {
        const courseEntry = courseEntries[i]

        const courseCode = courseEntry?.dataset?.kurssi || ''

        const rightBoxEls = courseEntry.getElementsByClassName('oikea-boksi')

        if(rightBoxEls.length !== 1) continue

        const rightBoxEl = rightBoxEls[0]

        const courseOwned = userData.products.find(p => p.sku === courseCode)

        if(courseOwned) rightBoxEl.style.display = 'none'
    }
}



//
//
//  █████╗  ██████╗ ██████╗ ██████╗ ██╗   ██╗███╗   ██╗████████╗
// ██╔══██╗██╔════╝██╔════╝██╔═══██╗██║   ██║████╗  ██║╚══██╔══╝
// ███████║██║     ██║     ██║   ██║██║   ██║██╔██╗ ██║   ██║
// ██╔══██║██║     ██║     ██║   ██║██║   ██║██║╚██╗██║   ██║
// ██║  ██║╚██████╗╚██████╗╚██████╔╝╚██████╔╝██║ ╚████║   ██║
// ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝
//
//
async function setAccountPage(userData) {
    const accountEl = document.getElementById('tili-kayttajatiedot')
    const productsEl = document.getElementById('tili-sivu-ostetut-tuotteet')

    if(!accountEl || !productsEl) return

    accountEl.innerHTML = `
        <strong>${userData.firstname} ${userData.lastname}</strong>
        <br>
        ${userData.email}
    `

    if(!Array.isArray(userData?.products) || userData.products.length === 0) return

    const productDescriptions = {
        'devops-perusteet-1': 'DevOps perusteet 1/2: Virtuaalipalvelin pilveen',
        'devops-perusteet-2': 'DevOps perusteet 2/2: Websovellus Linux-palvelimelle'
    }

    const { default: dayjs } = await import('dayjs')

    let html = ''

    for(let product of userData.products) {
        const productDesc = productDescriptions[product.sku] || product.sku

        let purchasedAtHtml = ''

        const purchasedAt = dayjs(product.purchased_at)

        if(purchasedAt.isValid()) {
            purchasedAtHtml = `
            <div class="oikea-alateksti tili">${purchasedAt.format("D.M.YYYY")}</div>
            `
        }

        html += `
        <section class="kurssi-entry">
          <a class="tili" href="/${product.sku}" title="${productDesc}">
            <div class="vasen-content">
              <h2 class="tili">${productDesc}</h2>
            </div>
            <div class="oikea-boksi tili">
              <div class="oikea-teksti tili">Ostettu</div>
              ${purchasedAtHtml}
            </div>
          </a>
        </section>
        `
    }

    productsEl.innerHTML = html
}



//
//
//  ██████╗ ██████╗ ██╗   ██╗██████╗ ███████╗███████╗
// ██╔════╝██╔═══██╗██║   ██║██╔══██╗██╔════╝██╔════╝
// ██║     ██║   ██║██║   ██║██████╔╝███████╗█████╗
// ██║     ██║   ██║██║   ██║██╔══██╗╚════██║██╔══╝
// ╚██████╗╚██████╔╝╚██████╔╝██║  ██║███████║███████╗
//  ╚═════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝
//
//
async function setCoursePage(userData) {
    const courseEl = document.getElementById('kurssi-sisalto')
    const course = courseEl?.dataset?.kurssi

    if(!course) return

    const url = `/api/kurssidata/${course}`

    try {
        const response = await backendApiKutsu('GET', url)

        courseEl.innerHTML = response.courseHtml
    
        await nextTick()
        await nextTick()
    
        // Dynamic import highlight.js stuff
        const { default: hljs }         = await import('highlight.js/lib/core')
        const { default: css }          = await import('highlight.js/lib/languages/css')
        const { default: dockerfile }   = await import('highlight.js/lib/languages/dockerfile')
        const { default: javascript }   = await import('highlight.js/lib/languages/javascript')
        const { default: nginx }        = await import('highlight.js/lib/languages/nginx')
        const { default: plaintext }    = await import('highlight.js/lib/languages/plaintext')
        const { default: xml }          = await import('highlight.js/lib/languages/xml')
        const { default: yaml }         = await import('highlight.js/lib/languages/yaml')
    
        await import('highlight.js/styles/monokai-sublime.css')
    
        hljs.registerLanguage('css',        css)
        hljs.registerLanguage('dockerfile', dockerfile)
        hljs.registerLanguage('html',       xml)
        hljs.registerLanguage('javascript', javascript)
        hljs.registerLanguage('nginx',      nginx)
        hljs.registerLanguage('plaintext',  plaintext)
        hljs.registerLanguage('yaml',       yaml)
    
        hljs.highlightAll()
    
        restoreScrollPosition(course)
    } catch(error) {
        if(error?.status === 403) {
            const loginOpenCourseEl = document.getElementById('kirjaudu-avaa-kurssi')
            loginOpenCourseEl.style.display = 'none'

            const buyCourseForUserTextEl = document.getElementById('osta-kurssi-kayttajalle-teksti')
            buyCourseForUserTextEl.innerHTML = `Kurssi liitetään nykyiseen käyttäjätiliisi kun syötät ostaessa sähköpostin: <strong>${userData.email}</strong>`

            const buyCourseLinkEl = document.getElementById('osta-kurssi-linkki')

            buyCourseLinkEl.href = `${buyCourseLinkEl.href}?email=${userData.email}`
        } else {
            const buyOrOpenCourseEl = document.getElementById('osta-avaa-kurssi-sisalto')
            buyOrOpenCourseEl.style.display = 'none'

            const openCourseErrorEl = document.getElementById('osta-avaa-kurssi-virhe')
            openCourseErrorEl.style.display = 'block'

        }

    } finally {

    }
}


function restoreScrollPosition(course) {
    const scrollPos = localStorage.getItem(`${course}_scrollPos`)

    if(scrollPos) window.scrollTo(0, scrollPos)
}



//
//
// ███╗   ███╗██╗███████╗ ██████╗
// ████╗ ████║██║██╔════╝██╔════╝
// ██╔████╔██║██║███████╗██║
// ██║╚██╔╝██║██║╚════██║██║
// ██║ ╚═╝ ██║██║███████║╚██████╗
// ╚═╝     ╚═╝╚═╝╚══════╝ ╚═════╝
//
//

function showLoadingIndicator(text = '') {
    loginLoading()

    const loadingIndicatorEl = document.getElementById('loading-indicator')

    if(!loadingIndicatorEl) return

    loadingIndicatorEl.style.display = 'flex'

    const loadingTextEl = document.getElementById('loading-indicator-text')

    if(!loadingTextEl) return

    if(text) {
        loadingTextEl.style.display = 'block'
        loadingTextEl.innerHTML = text
    } else {
        loadingTextEl.style.display = 'none'
    }
}

function hideLoadingIndicator() {
    loginLoading(false)

    const loadingIndicatorEl = document.getElementById('loading-indicator')

    if(!loadingIndicatorEl) return

    loadingIndicatorEl.style.display = 'none'

    const loadingTextEl = document.getElementById('loading-indicator-text')

    if(!loadingTextEl) return

    loadingTextEl.display = 'none'
    loadingTextEl.innerHTML = ''
}

function loginLoading(loading = true) {
    const loginButtonEl = document.getElementById('tieto-kirjaudu-nappi')

    if(loading) {
        loginButtonEl.classList.add('is-loading')
    } else {
        loginButtonEl.classList.remove('is-loading')
    }

    const courseLoginButtonEl = document.getElementById('kurssi-kirjaudu-nappi')

    if(!courseLoginButtonEl) return

    if(loading) {
        courseLoginButtonEl.classList.add('is-loading')
    } else {
        courseLoginButtonEl.classList.remove('is-loading')
    }
}



function toggleLightDarkMode() {
    const htmlEl = document.documentElement

    let currentTheme = htmlEl.dataset.theme

    let newTheme

    if(currentTheme === 'light') newTheme = 'dark'
    if(currentTheme === 'dark')  newTheme = 'light'

    document.documentElement.setAttribute('data-theme', newTheme)

    localStorage.setItem('tieto_kurssit_theme', newTheme)
}


function nextTick() {
    return new Promise(resolve => requestAnimationFrame(resolve))
}



//
//
// ██████╗  ██████╗ ███╗   ███╗
// ██╔══██╗██╔═══██╗████╗ ████║
// ██║  ██║██║   ██║██╔████╔██║
// ██║  ██║██║   ██║██║╚██╔╝██║
// ██████╔╝╚██████╔╝██║ ╚═╝ ██║
// ╚═════╝  ╚═════╝ ╚═╝     ╚═╝
//
//
document.addEventListener('DOMContentLoaded', () => {
    MicroModal.init()

    //
    // Login dropdown bindings
    //
    const loginMenuEl = document.getElementById('login-menu')
    const loginMenuTriggerEl = document.getElementById('login-menu-trigger')
    if(loginMenuEl && loginMenuTriggerEl) {
        loginMenuTriggerEl.addEventListener('click', () => {
            loginMenuEl.classList.toggle('is-active')
        })
    }

    document.addEventListener('click', e => {
        if(!loginMenuEl.contains(e.target)) {
            loginMenuEl.classList.remove('is-active')
        }
    })

    const loginFormEl = document.getElementById('tieto-login-form')
    loginFormEl.addEventListener('submit', login.bind(null, 'top-login'))

    const courseLoginFormEl = document.getElementById('kurssi-login-form')
    if(courseLoginFormEl) {
        courseLoginFormEl.addEventListener('submit', login.bind(null, 'course-login'))
    }


    //
    // User dropdown bindings
    //
    const userMenuEl = document.getElementById('user-menu')
    const userMenuTriggerEl = document.getElementById('user-menu-trigger')
    if(userMenuEl && userMenuTriggerEl) {
        userMenuTriggerEl.addEventListener('click', () => {
            userMenuEl.classList.toggle('is-active')
        })

        const logoutLinkEl = document.getElementById('kirjaudu-ulos-linkki')

        if(logoutLinkEl) {
            logoutLinkEl.addEventListener('click', () => {
                logout()
            })
    
        }
    }

    document.addEventListener('click', e => {
        if(!userMenuEl.contains(e.target)) {
            userMenuEl.classList.remove('is-active')
        }
    })


    const lightDarkTogglerEl = document.getElementById('light-dark-toggler')

    if(lightDarkTogglerEl) {
        console.log('test')
        lightDarkTogglerEl.addEventListener('pointerup', toggleLightDarkMode)
    }

    authenticate()
})



//
//
//  █████╗ ██████╗ ██╗
// ██╔══██╗██╔══██╗██║
// ███████║██████╔╝██║
// ██╔══██║██╔═══╝ ██║
// ██║  ██║██║     ██║
// ╚═╝  ╚═╝╚═╝     ╚═╝
//
//
async function backendApiKutsu(method, apiUrl, data = null) {
    const options = {
        method, headers: {},
    }

    const jwt = sessionStorage.getItem('tieto-authorization')

    if(jwt) options.headers['Authorization'] = `Bearer ${jwt}`

    if(data) {
        // Asetetaan Content-Type koska lähetämme JSON muotoista dataa
        options['headers']['Content-Type'] = 'application/json'
        options['body'] = JSON.stringify(data)
    }

    const response = await fetch(apiUrl, options)

    if(!response.ok) {
        const error = new Error(`${apiUrl}: ${response.status} ${response.statusText}`)
        error.status = response.status
        error.body = await response.text()

        throw error
    }

    const body = await response.text()

    try {
        return JSON.parse(body)
    } catch(error) {
        return body
    }

}